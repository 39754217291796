// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import 'variables.scss';

@mixin respond-to($breakpoint) {
    @if not map-has-key($breakpoints, $breakpoint) {
        @error "La opción #{$breakpoint}, no se encuentra entre las disponibles.";
    }
    @media (map-get($breakpoints, $breakpoint)){
        @content;
    }
}
