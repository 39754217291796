// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
	font-family: 'EncodeSans';
	src: url('../assets/fonts/EncodeSans/EncodeSans-Regular.ttf');
}

@font-face {
	font-family: 'EncodeSans-Thin';
	src: url('../assets/fonts/EncodeSans/EncodeSans-Thin.ttf');
}

@font-face {
	font-family: 'EncodeSans-Light';
	src: url('../assets/fonts/EncodeSans/EncodeSans-Light.ttf');
}

@font-face {
	font-family: 'EncodeSans-Bold';
	src: url('../assets/fonts/EncodeSans/EncodeSans-Bold.ttf');
}

@font-face {
	font-family: 'Colvetica';
	src: url('../assets/fonts/Coolvetica/coolvetica\ rg.otf');
}

/** Ionic CSS Variables **/
:root {
	--ion-font-family: EncodeSans;
	--font-bold: EncodeSans-Bold;
	--font-thin: EncodeSans-Thin;
	--font-light: EncodeSans-Light;
	--font-title: Colvetica;
	--main-color: #424242;
	--ion-text-color: #424242;

	//Global grids paddings (if DONT HAVE back arrow)
	--p-top: 2.5rem;
	--p-left: 3rem;
	--p-right: 3rem;

	//Global grids paddings (if HAVE back arrow)
	--pa-top: 2.5rem;
	--pa-left: 3rem;
	--pa-right: 3rem;

	//Margin bottom titles
	--m-bottom-title: 2.875rem;

	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** Toolbar **/
	--ion-color-toolbar: transparent;
	--ion-color-toolbar-rgb: 56, 128, 255;
	--ion-color-toolbar-contrast: rgb(159, 33, 197);
	--ion-color-toolbar-contrast-rgb: 255, 255, 255;
	--ion-color-toolbar-shade: transparent;
	--ion-color-toolbar-tint: transparent;
}

.ion-color-toolbar {
	--ion-color-base: var(--ion-color-toolbar);
	--ion-color-base-rgb: var(--ion-color-toolbar-rgb);
	--ion-color-contrast: var(--ion-color-toolbar-contrast);
	--ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb);
	--ion-color-shade: var(--ion-color-toolbar-shade);
	--ion-color-tint: var(--ion-color-toolbar-tint);
}

$breakpoints: (
	extraLarge: 'max-width: 2560px',
	superLarge: 'max-width: 1800px',
	mediumLarge: 'max-width: 1510px',
	desktop: 'max-width: 1400px',
	desktop-medium: 'max-width: 1300px',
	large: 'max-width: 1200px',
	medium: 'max-width: 992px',
	smallMedium: 'max-width: 768px',
	ipadMedium: 'max-width: 1024px',
	small: 'max-width: 320px',
	mobile-l: 'max-width: 330px',
	mobile: 'max-width: 375px',
	smartphone: 'max-width: 400px',
	landscapemobile: 'max-width: 640px',
	iphoneSix: 'max-height: 670px',
	macBookPro: 'max-width: 1637px',
	laptop-l: 'max-width: 1366px',
	mobile-l-height: 'min-height: 740px) and (max-height: 900px',
	tablet:
		'min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape',
	md-no-ipad: 'min-width: 992px) and(max-width: 1023px',
	ipad: 'min-width: 1024px) and (max-width: 1200px',
);
