/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/css/navigation';
@import '~@ionic/angular/css/ionic-swiper';
@import '~@ionic/angular/css/ionic-swiper';
@import '/src/theme/mixins';

ion-content {
	--offset-bottom: -48px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

.title-strong {
	font-size: 1.5rem;
	font-family: var(--font-bold);
	margin-bottom: 1rem;
	color: #424242;
	@include respond-to(large) {
		font-size: 1.47rem;
	}
	@include respond-to(mobile-l) {
		font-size: 1rem;
	}
}

.title-light {
	font-size: 1rem;
}

.title-strong-small {
	color: var(--font-bold);
	font-size: 20px !important;
	font-family: var(--font-bold) !important;
	margin-bottom: 14px;
}

.text-light {
	font-family: var(--font-light) !important;
	font-size: 16px !important;
	margin-bottom: 9px;
}

p {
	margin: 0px;
}

ion-item {
	--padding-start: 0px;
	--border-color: white;
}

.icon {
	width: 28px;
	object-fit: cover;
	margin-right: 10px;
}

ion-toolbar {
	padding-left: 0px !important;
}

//Contents without back button/arrow
.main-content {
	// padding-top: var(--p-top);
	// padding-right: var(--p-right);
	// padding-left: var(--p-left);
	padding-bottom: 48px;
}

//Contents with back button/arrow
.main-content-arrow {
	padding-right: var(--pa-right);
	padding-left: var(--pa-left);
	padding-bottom: 48px;
}

.absolute-content {
	padding-right: var(--pa-right);
	padding-left: var(--pa-left);
}

.btn-content {
	padding-left: var(--p-left);
	padding-right: var(--p-left);
}

//Light button
.btn-light {
	height: 2.125rem;
	width: 20%;
	font-family: var(--font-bold);
	font-size: 1rem;
	--background: white;
	--background-hover: white;
	--background-focused: white;
	--background-activated: white;
	--color: var(--font-color);
	border: 1px solid #424242;
	margin-inline: 0px;
}

//Guest button
.btn-guest {
	height: 2.35rem;
	width: 100%;
	font-family: var(--font-bold);
	font-size: 1rem;
	--background: #424242;
	--background-hover: #424242;
	--background-focused: #424242;
	--background-activated: #424242;
	--color: white;
	border: 1px solid #424242;
	margin-inline: 0px;
	margin-top: 2.5rem;
	@include respond-to(large) {
		height: 2.125rem;
		width: 100%;
		font-family: var(--font-bold);
		font-size: 1rem;
		--background: white;
		--background-hover: white;
		--background-focused: white;
		--background-activated: white;
		--color: var(--font-color);
		border: 1px solid #424242;
		margin-inline: 0px;
	}
}

//Facebook button
.btn-facebook {
	height: 2.35rem;
	width: 100%;
	font-family: var(--font-bold);
	font-size: 1rem;
	--background: #3880ff;
	--background-hover: #5f96f7;
	--background-focused: #5f96f7;
	--background-activated: #3880ff;
	--color: white;
	border: none;
	margin-inline: 0px;
	margin-top: 1.1rem;
}

//Google button
.btn-google {
	height: 2.35rem;
	width: 100%;
	font-family: var(--font-bold);
	font-size: 1rem;
	--background: #DD4B39;
	--background-hover: #e66351;
	--background-focused: #e66351;
	--background-activated: #DD4B39;
	--color: white;
	border: none;
	margin-inline: 0px;
	margin-top: 1.1rem;
}

//Dark button
.btn-dark {
	height: 2.3125rem;
	width: 100%;
	font-size: 1rem;
	--background: var(--main-color);
	--background-hover: white;
	--background-focused: var(--main-color);
	--background-activated: var(--main-color);
	--color: white;
	//border: 1px solid var(--main-color);
	margin-inline: 0;
	--border-radius: 0px;
	letter-spacing: normal;
	font-family: var(--font-bold);
}

//Dark button
.btn-dark:hover {
	--color: #424242;
	--background: white;
	border: 1px solid var(--main-color);
}

//Arrow back button
.back-btn {
	width: 1.3125rem !important;
	opacity: 85%;
	position: relative;
	left: -1.8125rem;
	top: -0.45rem;
	@include respond-to(large) {
		width: 1rem !important;
		left: -1.7125rem;
	}
}

app-custom-purchase-button {
	width: 100%;
	@include respond-to(large) {
		width: 22rem;
		margin-right: 150px;
	}
}

//Align title with the content when an arrow/back button is added
.offset {
	position: relative;
	left: -21px;
}

//Scrollbar

ion-popover {
	--ion-backdrop-color: white;
	--backdrop-opacity: 60%;
	--box-shadow: 0px 4px 13px -2px rgb(120 120 120);
}

.small {
	width: 7.1875rem;
	margin: 0px;
	padding: 0px;
	.searchbar-input-container {
		.searchbar-input {
			padding-right: 0px;
			font-size: 0.75rem !important;
			text-decoration: underline;
			padding: 0px;
			--box-shadow: none;
		}
		ion-icon {
			display: none !important;
		}
	}
}

.big {
	.searchbar-input {
		font-size: 1.15rem;
		color: var(--font-color-light);
		opacity: 80%;
		border-bottom: 1px solid #424242;
		border-radius: 0px;
		padding: 0px !important;
		@include respond-to(large) {
			font-size: 1rem;
		}
	}
	.searchbar-search-icon {
		display: none;
		width: 0px;
	}
}

.popover::part(content) {
	border-radius: 1rem;
	height: 25.375rem;
	--width: 700px;

	@include respond-to(large) {
		border-radius: 1rem;
		--width: 50rem;
		height: 19.375rem;

	}

	@media (max-width:920px) {
		border-radius: 1rem;
		height: 21.375rem;
		text-align: center;
		--width: 20rem;

	}
}

.popover::part(backdrop) {
	background-color: #ebebeb;
}

.categoryPopup::part(content) {
	border-radius: 1rem;
	--width: 16.5625rem;
}

ion-accordion-group {
	ion-accordion {
		.ion-accordion-toggle-icon {
			margin: 0px;
		}
	}
}

ion-radio::part(container) {
	border-color: #424242;
	border-width: 1px;
	padding: 2px;
}

ion-radio {
	margin-right: 0px;
	margin-bottom: 0px;
}

ion-radio::part(mark) {
	background: #cbbfbf;
	width: 100%;
	height: 100%;
}

ion-modal::part(content) {
	width: 100%;
	height: 100%;
}

.header-md::after {
	background-image: none;
}

.ion-row-mb{
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.swiper .swiper-pagination-bullet {
	opacity: 1;
	border: 1px solid #c7c7c7;
	margin-top: 8px !important;
	background: transparent;
}

.swiper .swiper-pagination-bullet-active {
	opacity: 1;
	border: none;
	margin-top: 8px !important;
	background: #c7c7c7;
}

.swiper-pagination-bullet {
	width: var(
		--swiper-pagination-bullet-width,
		var(--swiper-pagination-bullet-size, 12px)
	);
	height: var(
		--swiper-pagination-bullet-height,
		var(--swiper-pagination-bullet-size, 12px)
	);
	@include respond-to(large) {
		width: 8px;
		height: 8px;
	}
}

app-nav-bar {
	position: absolute;
	bottom: 0px;
	width: 100%;
}

.pointer {
	cursor: pointer;
}

.default {
	cursor: default;
}

.progress {
	cursor: progress;
}
